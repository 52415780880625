var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.clickable ? "router-link" : "div",
    {
      tag: "component",
      staticClass: "offering-card-wrapper",
      attrs: { to: _vm.route }
    },
    [
      _c(
        "div",
        { staticClass: "offering-card", class: { clickable: _vm.clickable } },
        [
          _c("div", { staticClass: "offering-card-left" }, [
            _vm.image && !_vm.imageErrored
              ? _c("img", {
                  staticClass: "offering-card-image",
                  attrs: { src: _vm.image, alt: "" }
                })
              : _c(
                  "div",
                  {
                    staticClass: "offering-card-image",
                    style: { background: _vm.$umodel.user_color(_vm.offering) }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$umodel.initials(_vm.offering.name)) +
                        " "
                    )
                  ]
                ),
            _c(
              "div",
              { staticClass: "offering-card-content" },
              [
                _c(
                  "div",
                  { staticClass: "offering-card-name-wrapper" },
                  [
                    _c("p", { staticClass: "offering-card-name" }, [
                      _vm._v(" " + _vm._s(_vm.offering.name) + " ")
                    ]),
                    _vm.showStatus
                      ? _c("StatusOrb", {
                          staticClass: "offering-card-name-status",
                          attrs: {
                            type: _vm.offering.status
                              ? _vm.offering.status === "published"
                                ? "green"
                                : "orange"
                              : "grey",
                            size: "xs",
                            text:
                              _vm.offering.status.capitalize() || "No content"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.attributes.length
                  ? _c("ORITagEdit", {
                      attrs: {
                        selected: _vm.attributes,
                        editable: false,
                        overflowing: true,
                        "tag-size": "xxs"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._t("right")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }