var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { "trap-focus": "" },
          on: { close: _vm.closeModal },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _vm.resourceLevel === "subresource"
            ? _c(
                "SlideVisualisationModal",
                _vm._b(
                  {
                    key: _vm.modalKey,
                    on: {
                      close: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  "SlideVisualisationModal",
                  _vm.props,
                  false
                )
              )
            : _vm._e(),
          _vm.resourceLevel === "resource"
            ? _c(
                "ResourceDetailModal",
                _vm._b(
                  {
                    key: _vm.modalKey,
                    on: {
                      gotoResource: _vm.onGoToResourceFromDetail,
                      "subresource-page-change": _vm.onSubresourcePageChange,
                      close: function($event) {
                        return _vm.$emit("close")
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "subHeader",
                          fn: function() {
                            return [
                              _vm._t("subHeader", null, {
                                resource: _vm.resource
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "ResourceDetailModal",
                  _vm.props,
                  false
                )
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }