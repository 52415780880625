<template>
  <component
    :is="clickable ? 'router-link' : 'div'"
    :to="route"
    class="inspiration-card-wrapper"
  >
    <div class="inspiration-card" :class="{ clickable }">
      <div class="inspiration-card-left">
        <img
          v-if="image && !imageErrored"
          class="inspiration-card-image"
          :src="image"
          alt=""
        />
        <div
          v-else
          class="inspiration-card-image"
          :style="{ background: $umodel.user_color(inspiration) }"
        >
          {{ $umodel.initials(inspiration.name) }}
        </div>
        <div class="inspiration-card-content">
          <p class="inspiration-card-name">{{ inspiration.name }}</p>
          <ORITagEdit
            v-if="attributes.length"
            :selected="attributes"
            :editable="false"
            :overflowing="true"
            tag-size="xxs"
          />
        </div>
      </div>
      <slot name="right"></slot>
    </div>
  </component>
</template>

<script>
import ORITagEdit from '../ori/ORITagEdit.vue'

export default {
  name: 'InspirationCard',
  components: { ORITagEdit },
  props: {
    inspiration: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    imageErrored: false
  }),
  computed: {
    image() {
      return this.inspiration.image || ''
    },
    attributes() {
      return this.inspiration?.attributes || []
    },
    route() {
      if (!this.clickable) return
      return {
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: 'inspirations',
          ori_id: this.inspiration.uuid
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.inspiration-card {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(#000, 8%);
  background: white;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  height: 100%;

  &-wrapper {
    height: 100%;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(#000, 16%);
      box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
    }
  }

  &-left {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    overflow: hidden;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    overflow: hidden;
  }

  &-image {
    width: 4rem;
    min-width: 4rem;
    max-width: 8rem;
    height: 4rem;
    min-height: 4rem;
    border-radius: 4px;
    object-fit: contain;
    overflow: hidden;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &-name {
    font-size: 1.14rem;
    font-weight: 600;
    color: #303032;
  }
}
</style>
